import { Injectable } from '@angular/core';
import {
  Observable,
  catchError,
  map,
  of,
  switchMap,
  tap,
  throwError,
} from 'rxjs';
import { UrlEnum } from '../../enum/url.enum';
import { HttpRequestService } from '../http-request/http-request.service';
import { HttpParams } from '@angular/common/http';
import { UtilitiesService } from '../utilities/utilities.service';
import { NgoModel } from '../../model/register-new-ngo.model';
import { ClaimNgoModel } from '../../model/claim-ngo.model';
import { IBookmarkRequest } from '../../interface/dashboard/bookmark.interface';
import { FileService } from '../file/file.service';

@Injectable({
  providedIn: 'root',
})
export class NgoService {
  private elasticsearchDocumentAPI = `${UrlEnum.SHOHAY_ELASTIC}/document`;
  private ngoAPI = `${UrlEnum.SHOHAY_NGO}/ngo-profile`;
  private bookmarkApi = UrlEnum.SHOHAY_NGO + `/bookmark`;

  constructor(
    private readonly fileService: FileService,
    private readonly utilitiesService: UtilitiesService,
    private readonly httpRequestService: HttpRequestService
  ) {}

  public searchNgo(query: string): Observable<any> {
    return this.httpRequestService
      .get(
        `${this.elasticsearchDocumentAPI}/search?index_name=ngo&query=${query}`,
        { skipAuth: true }
      )
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getNgoWithPagination(
    page: number,
    indexName: string = 'ngo'
  ): Observable<any> {
    const url = `${this.elasticsearchDocumentAPI}/all`;
    const params = new HttpParams()
      .set('index_name', indexName)
      .set('page', page.toString());

    return this.httpRequestService
      .get<any>(url, {
        params,
        skipAuth: true,
      })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public ngoWithBookmarIds(documentIds: string[]) {
    if (!documentIds || documentIds.length === 0) {
      console.error('No document IDs provided');
      return of([]);
    }
    const url = `${this.elasticsearchDocumentAPI}/get-by-ids`;
    const documentIdsParam =
      documentIds.length === 1 ? documentIds[0] : documentIds.join(',');
    console.log(documentIdsParam);
    const params = new HttpParams()
      .set('index_name', 'ngo')
      .set('document_Ids', documentIdsParam);

    return this.httpRequestService
      .get<any>(url, { params })
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public getNgoByUserId(userId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.ngoAPI}/get-by-user/${userId}`)
      .pipe(
        map((x: any) => this.utilitiesService.responseHandler(x)),
        map((x: any) =>
          x.sort(this.utilitiesService.dynamicSortWithDate('created_at', 'asc'))
        ),
        map((x: any) => this.utilitiesService.getSlNo(x))
      );
  }

  public updateNgoProfile(
    id: string,
    body: NgoModel,
    imgFile: File | null = null,
    imgProperty: string | null = null
  ): Observable<any> {
    return this.fileService.uploadSingleFile(imgFile).pipe(
      switchMap((imgResponse: any) => {
        if (imgResponse && imgResponse?.length > 0) {
          body[imgProperty] = JSON.stringify(imgResponse[0]);
        }
        return this.httpRequestService
          .patch(`${this.ngoAPI}/${id}`, body)
          .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
      }),
      catchError((error) => {
        return throwError(error);
      })
    );
  }

  public getNgoByElasticId(elasticId: string): Observable<any> {
    return (
      this.httpRequestService
        
        .get(`${this.ngoAPI}/get-by-ngo-elastic-id/${elasticId}`,{
          skipAuth: true,
        })
        .pipe(
          tap((x: any) => console.log(x)),
          map((x: any) => this.utilitiesService.responseHandler(x))
        )
    );
  }

  public registerNewNgo(body: NgoModel): Observable<any> {
    return this.httpRequestService
      .post(this.ngoAPI, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  public claimNgo(body: ClaimNgoModel): Observable<any> {
    const url = `${this.ngoAPI}/claim`;
    return this.httpRequestService
      .post(url, body)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }

  createBookmark(bookmark: IBookmarkRequest): Observable<any> {
    return this.httpRequestService.post(this.bookmarkApi, bookmark);
  }

  getBookmarkById(userId: string): Observable<any> {
    return this.httpRequestService.get(`${this.bookmarkApi}/${userId}`);
  }

  deleteBookmarkById(id: string): Observable<any> {
    return this.httpRequestService.delete(`${this.bookmarkApi}/${id}`);
  }

  public getNgoById(ngoId: string): Observable<any> {
    return this.httpRequestService
      .get(`${this.ngoAPI}/get-by-id/${ngoId}`)
      .pipe(map((x: any) => this.utilitiesService.responseHandler(x)));
  }
}
